import {
    createComponentExtension,
    createPlugin,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import {catalogPlugin} from "@backstage/plugin-catalog";

export const octoCatalogPlugin = createPlugin({
  id: 'octo-catalog',
  routes: {
    root: rootRouteRef,
  },
});

export const OctoEntityLinksCard = catalogPlugin.provide(
    createComponentExtension({
        name: 'OctoEntityLinksCard',
        component: {
            lazy: () =>
                import('./components/EntityLinksCard').then(m => m.EntityLinksCard),
        },
    }),
);
