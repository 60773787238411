import { SSLCertificate } from './types';
import {createApiRef, IdentityApi} from '@backstage/core-plugin-api';
import {CatalogApi, GetEntitiesRequest} from '@backstage/catalog-client';

export const sslCertificateManagerApiRef = createApiRef<SSLCertificateManager>({
    id: 'plugin.octo.certificate.manager.service',
});

type SSLCertificateFetchOpts = {
    limit?: number
    query?: string
}

export interface SSLCertificateManager {
    getSSLCertificates(opts?: SSLCertificateFetchOpts): Promise<SSLCertificate[]>;
}

type Options = {
    catalogApi: CatalogApi;
    identityApi: IdentityApi;
};

/**
 * API to talk to Opsgenie.
 */
export class SSLCertificateManagerApi implements SSLCertificateManager {
    private readonly catalogApi: CatalogApi;
    private readonly identityApi: IdentityApi;

    constructor(opts: Options) {
        this.catalogApi = opts.catalogApi;
        this.identityApi = opts.identityApi;
    }

    async getSSLCertificates(): Promise<SSLCertificate[]> {
        let sslCertificatesRequest: GetEntitiesRequest = {
            filter: [
                { kind: ['Resource'],
                  "spec.type": "ssl-certificate"
                }
            ]
        };
        let response = await this.catalogApi.getEntities(sslCertificatesRequest, await this.identityApi.getCredentials())
        console.log(response)
        return response.items;
    }


}