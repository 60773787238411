import {
    createApiFactory,
    createPlugin,
    createRoutableExtension,
    identityApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import {SSLCertificateManagerApi, sslCertificateManagerApiRef} from "./api";
import {catalogApiRef} from "@backstage/plugin-catalog-react";

export const octoCertManagerPlugin = createPlugin({
  id: 'octo-cert-manager',
  apis: [
    createApiFactory({
        api: sslCertificateManagerApiRef,
        deps: { catalogApi: catalogApiRef, identityApi: identityApiRef },
        factory: ({ catalogApi, identityApi}) => {
            return new SSLCertificateManagerApi({
                catalogApi: catalogApi,
                identityApi: identityApi,
            });
        },
  })],
  routes: {
    root: rootRouteRef,
  },
});

export const OctoCertManagerPage = octoCertManagerPlugin.provide(
  createRoutableExtension({
    name: 'OctoCertManagerPage',
    component: () =>
      import('./components/OctoCertManagerComponent').then(m => m.OctoCertManagerComponent),
    mountPoint: rootRouteRef,
  }),
);
